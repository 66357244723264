const defaultPoinOp = {
  legend: {
    textStyle: { color: '#767E87' }
  },
  tooltip: { show: false },
  dataset: {
    dimensions: ['product', '总分', '得分'],
    source: []
  },
  textStyle: { color: '#767E87' },
  xAxis: {
    type: 'category',
    axisLine: {
      lineStyle: {
        color: '#fff',
        width: 2
      }
    },
    splitArea: {
      //--网格区域
      show: false //---是否显示，默认false
    }
  },
  yAxis: {
    show: false
  },
  series: [
    {
      type: 'bar',
      itemStyle: {
        normal: {
          color: '#218ACE',
          barBorderRadius: [8, 8, 0, 0],
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#CBD5E1',
              fontSize: 14
            }
          }
        }
      }
    },
    {
      type: 'bar',
      itemStyle: {
        normal: {
          color: '#F4665C',
          barBorderRadius: [8, 8, 0, 0],
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#CBD5E1',
              fontSize: 14
            }
          }
        }
      }
    }
  ]
}
export default defaultPoinOp
