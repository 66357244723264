export const defaultOp = {
  title: {
    text: '表1 技能雷达图',
    textStyle: {
      color: '#333',
      fontSize: 16
    },
    // 标题位置
    padding: 3,
    left: 'center',
    bottom: 0
  },
  legend: {
    data: ['掌握率', '平均掌握率'],
    orient: 'vertical',
    left: 'right',
    top: 260
  },
  grid: { position: 'center' },
  tooltip: {
    show: true,
    confine: true,
    enterable: true
  },
  radar: {
    shape: 'rect',
    splitNumber: 5, // 雷达圈数
    name: {
      textStyle: {
        color: '#333'
      }
    },
    // 设置雷达图中间射线的颜色
    axisLine: {
      lineStyle: {
        color: '#dedede'
      }
    },
    indicator: [
      {
        name: '专业基础',
        max: 1,
        axisLabel: {
          show: true,
          fontSize: 12,
          color: '#838D9E',
          showMaxLabel: true, //不显示最大值，即外圈不显示数字30
          showMinLabel: true //显示最小数字，即中心点显示0
        }
      },
      { name: '编程基础', max: 1 },
      { name: '工程能力', max: 1 },
      { name: '编程操作', max: 1 }
    ],
    splitLine: {
      show: true,
      lineStyle: {
        width: 1,
        type: 'dashed',
        color: '#b3a1c7'
      }
    }
  },
  series: [{ name: '雷达图', type: 'radar', symbol: 'rect', data: [] }]
}
